<template>
  <div id="app"> 
    <a-locale-provider :locale="locale">
      <router-view />
    </a-locale-provider>
  </div>
</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  export default {
    data() {
      return {
        locale: zhCN,
      };
    },
  }
</script>

<style lang="less">
  html,body{
    width: 100vw;
    height: 100vh;
    .flex{
  display: flex;
}
.flex_center{
	display: flex;
	align-items: center;
}
.flex_c{
	display: flex;
	justify-content: center;
	align-items: center;
}
.flex_a{
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.flex_b{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.flex_e{
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.flex_column{
	display: flex;
	flex-direction: column;
}
.flex_Cc{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.flex_Ca{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.flex_Cb{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.flex_Ce{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}
.padding{
	padding: 20rpx;
	box-sizing: border-box;
}
.paddingLR{
	padding: 0 20rpx;
	box-sizing: border-box;
}
.paddingTB{
	padding: 20rpx 0;
	box-sizing: border-box;
}
.radius{
	border-radius: 20rpx;
}
.margin{
	margin: 20rpx;
}
.marginTop{
	margin-top: 20rpx;
}
.marginLR{
	margin: 0 20rpx;
}
.marginTB{
	margin: 20rpx 0;
}
.help{
	height: 40rpx;
}

  }
</style>
