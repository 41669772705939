import axios from "axios";

const request = axios.create({
  // baseURL: "https://iot.shunengpower.com/api",
  baseURL: 'https://cloud.shunengpower.com/api',
  timeout: 8000
});
// 添加请求拦截器 
request.interceptors.request.use(function (config) {
  config.headers = {
    'Content-Type': 'application/json' //配置请求头 
  }
  let token = window.localStorage.getItem('token')
  if (token) {
    config.headers.token = token
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  const code = response.data.success;
  const msg = response.data.message;
  if (response.data.statusCode == 401) {
    location.href = '/login'
    window.localStorage.setItem('token', "")
  }else if(msg=="请求体应包含pid"){
    location.href = '/home'
    window.localStorage.setItem('token', "")
  }
  return response;
}, function (error) {
  // if(error.response.data.success == false && error.response.data.statusCode == 500){
  //   sessionStorage.removeItem('token');
  //   location.href='/login'
  // }
  return error;
});

export default request